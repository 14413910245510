import { useState, useEffect } from 'react';
import React from 'react';
import axios from 'axios';
import { Accordion, Modal, Tab, Table } from 'react-bootstrap';
import detailsIcon from "../../Assets/details-icon.png"
import './ReportsData.css'
import useGlobalData from '../../../Zustand/GlobalData';
import moment from 'moment';
import DataGridStyle from '../data-table/datagrid_style';
import { Box } from '@mui/material';

const SessionReport = (props) => {

    /*const { InitialUpdateAccountsData, accountId, usersData, schoolData, updateUsersData } = useGlobalData();
    const [reports, setReports] = useState([]);
    const [details, setDetails] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedDt2, setSelectedDt2] = useState(null);
    const [openAccordion, setOpenAccordion] = useState();*/

    const [__sessionReportData, __setSessionReportData] = useState([]);


    useEffect(() => {
        let accountId = localStorage.getItem('_accountId');
        console.log("SessionReports.js", accountId);
    
        axios.get(`/taratutor-usagereport/getReport/${accountId}`)
            .then(async (res) => {
                let __reportsSessionData = [];
                const _gradeCourses = JSON.parse(JSON.stringify(res?.data?.data?.flatMap((report) => report?.gradeCourses)));
    
                for (let dt2 of _gradeCourses) {
                    //get the user details
                    const _getUserDetails = await axios.get(`/customerUsers/${dt2?.userId}`);

                    //Calculation the maxUnit and maxLesson
                    let __maxUnit = 0;
                    let __maxLesson = 0;
                    for (let session of dt2?.sessionDetails) {
                        if (session.lesson > __maxLesson) {  __maxLesson = session.lesson; }
                        if (session.unit > __maxUnit) {  __maxUnit = session.unit; }
                    }
    
                    if (Array.isArray(dt2?.sessionDetails)) {
                        for (let session of dt2?.sessionDetails) {    
                            const _start = moment(session.sessStart);
                            const _end = moment(session.sessEnd);
                            const _durationInSecs = _end.diff(_start, 'seconds');
                            const _timeinhhmmss = moment.utc(_durationInSecs * 1000).format('HH:mm:ss');
    
                            const _sessionData = {
                                "id": session?._id,
                                "customerName": _getUserDetails?.data?.data?.customerAccountDetails?.customerName,
                                "userEmail": _getUserDetails?.data?.data?.userEmail,
                                "userName": _getUserDetails?.data.data?.userName,
                                "courseName": dt2?.course,
                                "role": dt2?.role,
                                "grade": dt2?.grade,
                                "start_date": __formatDate(session?.sessStart),
                                "start_time": __formatTime(session?.sessStart),
                                "end_date": __formatDate(session?.sessEnd),
                                "end_time": __formatTime(session?.sessEnd),
                                "duration": _durationInSecs,
                                "hh:mm:ss": _timeinhhmmss,
                                "unit": session?.unit,
                                "lesson": session?.lesson,   
                                "maxUnit": __maxUnit,
                                "maxLesson": __maxLesson,
                            };    
                            __reportsSessionData.push(_sessionData);
                        }
                    } else {
                        console.log("Invalid sessionDetails format for", dt2);
                    }
                }
                __setSessionReportData(__reportsSessionData);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, []);
    
    

    const __formatDate = (aDateFormat)=> {
        return moment(aDateFormat).format('LL');
    }

    const __formatTime = (aDateFormat)=> {
        return moment(aDateFormat).format('LTS');
    }


    const handleModalOpen = (dt2) => {
        //setSelectedDt2(dt2);
        //setShowModal(true);
    };


    const handleModalClose = () => {
        ////setSelectedDt2(null);
        //setShowModal(false);
    };


    const toggleAccordion = (index) => {
        //setOpenAccordion((prevIndex) => (prevIndex === index ? null : index));
        // setOpenAccordion(index)
    };
    

    const getDate = (arg) => {
        const dateString = arg
        const dateObject = new Date(dateString);

        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();

        const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
        return formattedDate;
    }

    const getTime = (arg1, arg2) => {
        const startTime = new Date(arg1);
        const endTime = new Date(arg2);

        const timeDifference = endTime - startTime;

        const hours = Math.floor(timeDifference / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60).toString().padStart(2, '0');
        const seconds = Math.floor((timeDifference / 1000) % 60).toString().padStart(2, '0');

        const formattedTimeDuration = `${hours}:${minutes}:${seconds}`;
        return formattedTimeDuration;
    }

    const getTotalTimeDuration = (totalMilliseconds) => {
        const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((totalMilliseconds / (1000 * 60)) % 60).toString().padStart(2, '0');
        const seconds = Math.floor((totalMilliseconds / 1000) % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }

    const convertToSeconds = (time) => {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };

    const addTime = (totalTimeSpentDuration, newTime) => {
        console.log("sssssssss", totalTimeSpentDuration, newTime);
        let seconds = convertToSeconds(newTime);

        let temp = parseInt(totalTimeSpentDuration) + parseInt(seconds);

        return temp;
    }

    const convertSecondsToTimeFormat = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(remainingSeconds).padStart(2, "0");

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };


    return (
        <div >

<Box
                sx={{
                   /* height: "auto",
                    width: "100%",
                    //maxWidth:"1024px",
                    overflowX: "scroll", // Use "auto" for scrollbars to appear only when necessary
                    ul: {
                        bgcolor: "#f6f6f6",
                        color: "#ffffff",
                    } */

                        height: "auto",
    maxWidth: "100%", // Restricts the width of the Box to the viewport or container
    overflowX: "auto", // Enables horizontal scrolling inside the Box
    overflowY: "hidden", 
                }}
            >
                
                <DataGridStyle
                
                rows={__sessionReportData}
                columns={[                       
                    {
                        field: "customerName",
                        headerName: "Customer Name",
                        minWidth: 200,
                    }, 
                    {
                        field: "userName",
                        headerName: "User Name",
                        minWidth: 180,
                    },    
                    {
                        field: "userEmail",
                        headerName: "User Email",
                        minWidth: 240,
                    },                      
                    {
                        field: "courseName",
                        headerName: "Course",
                        minWidth: 100,
                    }, 
                    {
                        field: "grade",
                        headerName: "Grade & Section",
                        minWidth: 150,
                    },                 
                    {
                        field: "role",
                        headerName: "Role",
                        minWidth: 80,
                    },
                    {
                        field: "start_date",
                        headerName: "Start Date",
                        minWidth: 150,
                    },
                    {
                        field: "start_time",
                        headerName: "Start time",
                        minWidth: 130,
                    },
                    {
                        field: "end_date",
                        headerName: "End Date",
                        minWidth: 150,
                    },
                    {
                        field: "end_time",
                        headerName: "End time",
                        minWidth: 130,
                    },
                    {
                        field: "duration",
                        headerName: "Duration (In Secs)",
                        minWidth: 165,
                    },
                    {
                        field: "hh:mm:ss",
                        headerName: "hh:mm:ss",
                        minWidth: 120,
                    },
                    {
                        field: "unit",
                        headerName: "Current Unit",
                        minWidth: 150,
                    },
                    {
                        field: "lesson",
                        headerName: "Current Lesson",
                        minWidth: 150,
                    },
                    {
                        field: "maxUnit",
                        headerName: "Max Unit",
                        minWidth: 100,
                    },
                    {
                        field: "maxLesson",
                        headerName: "Max lesson",
                        minWidth: 100,
                    },
                   
                ]}
                disableSelectionOnClick
                autoHeight={true}
                checkboxSelection={false}        
                sx={{
                    "& .MuiDataGrid-root": {
                      width: 800, // Total width of all columns to ensure scrolling
                    },
                  }}
                downloadXLFileName="session report"
                />
               
            </Box>


           {/*} <Table className='mt-3' bordered responsive hover style={{ backgroundColor: "white" }}>
                <thead>
                    <tr>
                        <th>Reg. No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Course</th>
                        <th>Grade</th>
                        <th>Role</th>
                        <th>Unit</th>
                        <th>Lesson</th>
                        <th>Sesion Start Date</th>
                        <th>Session End Date</th>
                        <th>Time Spent</th>
                        <th>Time Spent in ms</th>
                        <th>Session Number</th>
                    </tr>
                </thead>
                <tbody>
                    {reports?.flatMap((report) => report?.gradeCourses).map((dt2, index) => {
                        let totalTimeSpentDuration = 0;
                        let overallTimeSpent = 0;

                       return  dt2?.sessionDetails.map((session, index) => {
                        console.log("Session Reports sessionDetails",dt2);
                        let start = moment(session.startDate); // some random moment in time (in ms)
                        let end = moment(session.endDate); // some random moment after start (in ms)
                        let diff = end.diff(start, 'milliseconds');
                        let diffSeconds = end.diff(start, 'seconds');
                        let timeinhhmmss = moment.utc(diff).format('HH:mm:ss');
                            return (
                                <React.Fragment key={dt2._id}>
                                    <tr onClick={() => toggleAccordion(index)}>
                                        <td>{dt2?.regNum}</td>
                                        <td>{dt2?.userName}</td>
                                        <td>{dt2?.userEmail}</td>
                                        <td>{dt2.course}</td>
                                        <td>{dt2.grade}</td>
                                        <td>{dt2.userRole}</td>
                                        <td>{dt2.unit}</td>
                                        <td>{dt2.lesson}</td>
                                        <td>{moment(session.startDate).format('LL')} {moment(session.startDate).format('LTS')}</td>
                                        <td>{moment(session.endDate).format('LL')} {moment(session.endDate).format('LTS')}</td>
                                        <td>{timeinhhmmss}</td>
                                        <td>{diffSeconds}</td>
                                        <td>{index+1}</td>
                                    </tr>
                                </React.Fragment>
                            );
                        })
                      
                    })}
                </tbody>
            </Table> {*/}
        </div>

    );
};


export default SessionReport;






