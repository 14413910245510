import React, { useState, useCallback, useEffect } from 'react'
import useGlobalData from '../../../Zustand/GlobalData'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import AddSchoolUser from './AddSchoolUser';
import PaginationCompnt from '../../CmnCompnts/PaginationCompnt';
import NotificationPopup from '../../CmnCompnts/NotificationPopup';
import editBtnImg from "../../Assets/edit.svg";
import deleteBtnImg from "../../Assets/delete.svg";
import AddSingleUser from './AddSingleUser';
import PopupModalAssgnedUsers from './PopupModalAssgnedUsers';
import { CSVLink } from 'react-csv';
import DeletePopup from './DeletePopup';
import SearchBar from '../../CmnCompnts/SearchBar';
import ResetBtnImg from '../../Assets/reset.svg'
import ReportsData from './ReportsData';
import ReportsDataNew from './ReportsDataNew';
import SessionReport from './SessionReport';
import PageHeading from '../page_heading';
import CustomButton from "../common/button_one";
import ReportIcon from '@mui/icons-material/Report';
import { basic } from '../../../themes/basic';

export default function Reports(props) {

  const { InitialUpdateAccountsData, accountId, usersData, schoolData, updateUsersData } = useGlobalData();
  const [crntData, SetCrntData] = useState("student");
  const [addedUsersData, setAddedUsersData] = useState([])
  const [gradesList, setGradesList] = useState([])
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sendEmailNotification, setsendEmailNotification] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("")
  const [tab, setTab] = useState('session');

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [resetVal, setReset] = useState(false);
  const [showReports, setShowReports] = useState(true);
  const [reportsData, setReportsData] = useState([]);
  const [sessionData, setSessionData] = useState([]);

  // const [roleSelectedVal, setRoleSelectedVal] = useState("")
  // const [gradesSelectedVal, setGradesSelectedVal] = useState("")

  const [slctdRole, setSlctdRole] = useState("")
  const [slctdGrade, setSelectedGrade] = useState("");

  const [showMsg, setShowMsg] = useState({
    message: "",
    type: ""
  });

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChangeTabs = (aTabValue) => {
    //const id = event.target.id;
    console.log("handleChangeTabs",aTabValue);
    //setTab("");
    /*if (id === 'users') {
      setTab('users');
      setShowReports(false);
    } else*/
    if (aTabValue === 'report') {
      setTab('report');
      setShowReports(true);
    } else if (aTabValue === 'session') {
      setTab('session');
    }
  };

  // const handleEditUser = (user, index) => {
  const handleEditUser = (user) => {
    setSelectedUser(user);
    setRole(user.userRole);
    setShowModal(true);
  };

  useEffect(() => {
    //console.log("schoolDashboard/reports",usersData);
    setFilteredUsers(usersData)
    setFilteredSearchData(usersData)
    let grades = []
    usersData.map((dt, i) => {
      let gradeTemp = dt.grade.split(",");
      gradeTemp.map((gradeDt, k) => {
        if (!grades.includes(gradeDt)) {
          grades.push(gradeDt)
        }
      })
    })
    setGradesList(grades)
    // console.log("zzzzzzzzzzzzzzzzzzzz", grades);
  }, [schoolData, usersData])

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const showClikdData = (e) => {
    SetCrntData(e.target.id)
  }



  useEffect(() => {
    filterMultipleFields();
  }, [slctdGrade, slctdRole, searchTxt])


  const filterUsersOnRole = (roleVal) => {
    if (roleVal.toLowerCase() == "all") {
      setSlctdRole("")
    }
    else {
      setSlctdRole(roleVal)
    }
  };


  const filterByGrade = (gradeVal) => {
    if (gradeVal.toLowerCase() == "all") {
      setSelectedGrade("")
    }
    else {
      setSelectedGrade(gradeVal)
    }
  }

  const filterMultipleFields = () => {
    //console.log("Ssssssssssss", slctdGrade, "--", slctdRole);

    let searchItems = ["userName", "userEmail", "pin", "regNum", "OTP", "phoneNum"]

    let searchDt = usersData.filter((dt) => {
      return searchItems.some((item) => {
        return dt[item].toLowerCase().includes(searchTxt.toLowerCase());
      });
    })

    let temp = searchDt;

    if (slctdGrade && slctdRole) {
      temp = searchDt.filter((dt) => dt.grade.includes(slctdGrade) && dt.userRole.toLowerCase() === slctdRole.toLowerCase());
    } else if (slctdGrade) {
      temp = searchDt.filter((dt) => dt.grade.includes(slctdGrade));
    } else if (slctdRole) {
      temp = searchDt.filter((dt) => dt.userRole.toLowerCase() === slctdRole.toLowerCase());
    }

    setFilteredUsers(temp);
  };

  // Pagination logic for 10 rows in a page.
  const rowsPerPage = 10; // Number of rows to display on the first page
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedUsers = filteredUsers.slice(startIndex, endIndex);

  const onSendEmailClick = (dt) => {
    let payload = {
      userName: dt.userName,
      userEmail: dt.userEmail,
      pinNum: dt.OTP,
      pin: dt.pin,
      regNum: dt.regNum,
      schoolName: schoolData.schoolName,
    }
    axios.post("/customerUsers/sendEmail", payload)
      .then((res) => {
        console.log("Send Email ", res.data);
        setsendEmailNotification(true)
      })
      .catch((err) => {

      })
  }

  return (
    <div>
      <Container fluid={true} style={{ padding:"0px"}}>

        <PageHeading title="User Reports" alignment="left" />
        <Row style={{ display: 'flex', textAlign: "center" }}>
          <Col md={12} style={{ textAlign: "start", margin: "0px auto", height: "115px" }}>
            <Row>
              <Col className='mt-3' style={{ textAlign: "end" }} >
                <div className='d-flex align-items-center justify-content-start'>

                  

                  <CustomButton
                  id='session'
                  isActive= {tab==="session"}
                  //onClick={handleChangeTabs("session")}
                  onClick={() => handleChangeTabs("session")}
                  aria-label="Session Reports"
                  >
                      <ReportIcon fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-2}}/>
                      <div style={{ marginLeft: 5 }}>Session Reports</div>
                  </CustomButton>

                  <CustomButton
                  disabled
                  id='report'
                  isActive={tab==="report"}
                  onClick={() => handleChangeTabs("report")}
                  aria-label="Details Reports"
                  >
                      <ReportIcon fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-2}}/>
                      <div style={{ marginLeft: 5 }}>Details Reports</div>
                  </CustomButton>
                </div>
              </Col>

              <Col style={{ textAlign: "start" }} >
                <div className='d-flex align-items-center justify-content-end'>

                  { /*console.log("reportsData", reportsData)}
                  {tab === 'report' && (
                    < CSVLink className='ms-3 mt-3 btn-latest d-flex align-items-center justify-content-center' style={{ textDecoration: "none" }} data={reportsData} filename='lm_reports_data.csv'>
                      Export Reports CSV
                    </CSVLink>
                  )}
                   {console.log("sessionData", sessionData)}
                  {tab === 'session' && (
                    < CSVLink className='ms-3 mt-3 btn-latest d-flex align-items-center justify-content-center' style={{ textDecoration: "none" }} data={sessionData} filename='lm_session_data.csv'>
                      Export Session CSV
                    </CSVLink>
                  ) */}
                </div>
              </Col>
            </Row>
            <div>
              {tab === 'report' && <ReportsDataNew setReports={setReportsData}/> }
              {tab === 'session' && <SessionReport setReports={setSessionData}/> }
            </div>
            
            <div>
              {
                sendEmailNotification ? <NotificationPopup setShowMsg={setShowMsg} type={"success"} message={"Successfully sent email"} setShow={setsendEmailNotification} timer={2000} /> : ""
              }
            </div>
          </Col>
        </Row>
      </Container >
    </div >
  )
}

