import React, { useState, useEffect, Fragment  } from 'react';
import { Button, Box, IconButton,Typography,FormControlLabel } from '@mui/material';
import {Add as AddIcon, Edit as EditIcon, 
  Delete as DeleteIcon, Layers as CategoryIcon, ArrowForwardIos as ArrowIcon } from '@mui/icons-material';
import axios from 'axios';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DataGridStyle from '../data-table/datagrid_style';
import CustomButton  from '../common/button_one';
import BackButton from '../common/back_button';
import SwitchStyle from '../SwitchStyle';
import { basic } from '../../../themes/basic';
import ModelPopup from "../common/modal_popup";
import LoadingOverlay from '../common/loading_overlay';
import MessageSnackbar from '../common/message_snackbar';
import HeadingWithStep from './heading_steps';

const Course = () => {

  const [__selectedProductName, __setSelectedProductName] = useState(null);
  const [__selectedVariantName, __setSelectedVariantName] = useState(null);

  const [__courseData, __setCourseData] = useState([]);
  const [__selectedCourse, __setSelectedCourse] = useState([]);
  const __navigate = useNavigate() 
  
  const [__modalOpen, __setModalOpen] = useState(false);
  const [__loading, __setLoading] = useState(false);
  const [__descriptionMessage, __setDescriptionMessage] = useState(null);
  const [__successMsg, __setSuccessMsg] = useState(null);
  const [__messageStatus, __setMessageStatus] = useState(null);
  const [__deletedCourseId, __setDeletedCourseId] = useState(null);
  const [__deleteCourseName, __setDeleteCourseName] = useState(null);
  const [__selectedVariantId, __setSelectedVariantId] = useState(null);
  const [__selectedProductId, __setSelectedProductId] = useState(null);

  const [__snackbarOpen, __setSnackbarOpen] = useState(false);
  const [__snackbarMessage, __setSnackbarMessage] = useState("");
  const [__snackbarVariant, __setSnackbarVariant] = useState("success");

  //status update
  const [__statusChangeModalOpen, __setStatusChangeModalOpen] = useState(false);
  const [__statusChangeMessage, __setStatusChangeMessage] = useState(false);
  const [__statusChangeSelectedId, __setStatusChangeSelectedId] = useState(null);
  const [__statusChangeUpdatedValue, __setStatusChangeUpdatedValue] = useState(false);

  const [__loginUserId, __setLoginUserId] = useState("");

  // get variant details using by productId and variantId
  const __getProductDataById = async (aSelectedProductId) => {
    const _getProductData = await axios.get(`/product/${aSelectedProductId}`);
    __setSelectedProductName(_getProductData.data.data.productName);
    const _getProductAssocationData = _getProductData.data.data.productAssociation;

    let _variantIndex = _getProductAssocationData.findIndex(
      assoc => assoc.variantId._id.toString() === __selectedVariantId
    );

    const _getProductName = _getProductData.data.data.productName;
    const _getVariantname = _getProductAssocationData[_variantIndex]?.variantId.variantName;

    const _getCurrentCoursesData = _getProductAssocationData[_variantIndex]?.courses;
    if(_getCurrentCoursesData?.length > 0){
      const _courseDataRows = _getCurrentCoursesData?.map((aItem) => ({
        productName:_getProductName,
        variantName:_getVariantname,
        courseName:aItem.courseId?.courseName,
        courseDescription:aItem?.courseId?.courseDescription,
        isVisible:aItem?.isVisible ? 'enable' : 'disable',
        id: aItem?.courseId?._id,
      }));
      __setCourseData(_courseDataRows);
    }else{
      __setCourseData([]);
    }
  }


  const __handleClose = () => __setModalOpen(false);

  const __setSelectedCourseFunc = (aSelectedCourse) => {
    __setSelectedCourse(aSelectedCourse)
  };

  const __navigateToAddCoursePage = () => {
    __navigate("/adminDashboard/product/course/add");
  }

  const __handleEditCourse = (aCourseId) => {
    __navigate(`/adminDashboard/product/course/${aCourseId}`);
  }

  const __handleDeleteCourse = async (aCourseId,aCourseName) => {
      __setSnackbarOpen(false);
      __setDeletedCourseId(aCourseId);
      const _notifyMessage = `Are you sure you want to delete <b>${aCourseName}</b> course?`
      __setDescriptionMessage(_notifyMessage);
      __setModalOpen(true)
    
  }

  // get Variant name using by variant id
  const __getVariantNameById = async (aVariantId) => {
    const _getVariantData = await axios.get(`/variant/${aVariantId}`);
    __setSelectedVariantName(_getVariantData.data.data.variantName);
  }

  const __statusUpdateForCourse = async (aCourseId,aCourseName,aStatus) => {
    __setSnackbarOpen(false);
    __setStatusChangeSelectedId(aCourseId);
    __setStatusChangeUpdatedValue(aStatus);
    const _notifyMessage = `Are you sure you want to ${aStatus === false ? "disable" : "enable"} <b>${aCourseName}</b> course?`
    __setStatusChangeMessage(_notifyMessage);
    __setStatusChangeModalOpen(true);
  }

  const __updateCourseStatus = async () => {
    __setStatusChangeModalOpen(false);
    __setLoading(true);
    let _courseData = {
      isVisible: __statusChangeUpdatedValue,
      userId: __loginUserId
    };
    const _updateCourseData = await axios.put(`/course/statusUpdate/${__selectedProductId}/${__selectedVariantId}/${__statusChangeSelectedId}/`, _courseData);
    if(_updateCourseData.data.status=="success"){
      __setLoading(false);
      __getProductDataById(__selectedProductId);
      __triggerSnackbar(`Course has been ${__statusChangeUpdatedValue ? 'enabled' : 'disabled'} successfully.`,"success");
    }else{
      __setLoading(false);
      __triggerSnackbar("Failed to update the course.","error");
    }
  };

  const __handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    __setSnackbarOpen(false);
  };

  const __handleStatusChangeClose = () => __setStatusChangeModalOpen(false);

  const __triggerSnackbar = (message, variant) => {
    __setSnackbarMessage(message);
    __setSnackbarVariant(variant);
    __setSnackbarOpen(true);
  };

  const __courseDeleteConfirm = async () => {
    __setModalOpen(false);
    __setLoading(true);    
    const deleteCourseData = await axios.delete(`/course/delete/${__selectedProductId}/${__selectedVariantId}/${__deletedCourseId}`);
   
    if(deleteCourseData.data.status=="success"){             
      //__getCourseData();
      __getProductDataById(__selectedProductId);
      __setLoading(false);   
      __triggerSnackbar("Course deleted successfully.","success"); 
      //__setMessageStatus("success");
      //__setSuccessMsg("Course deleted successfully.");
    }else{
      __setLoading(false);   
      __triggerSnackbar("Failed to delete course.","error"); 
      //__setMessageStatus("failed");
      //__setSuccessMsg("Failed to delete course.");
    }
  }


  useEffect(() => {
    //sessionStorage.clear();
    const _getSessionCourseValue = sessionStorage.getItem("_selectedCourseID");
    if(_getSessionCourseValue){
      //__setSelectedCourse([_getSessionCourseValue]);
    }

    // To display the selected product name using by product id
    const _getSelectedProductId = sessionStorage.getItem("_selectedProductID");
    if(_getSelectedProductId){
      __setSelectedProductId(_getSelectedProductId);
      __getProductDataById(_getSelectedProductId);
    }

    // To display the selected variant name using by variant id
    const _getSelectedVariantId = sessionStorage.getItem("_selectedVariantID");
    if(_getSelectedVariantId){
      __setSelectedVariantId(_getSelectedVariantId);
      __getVariantNameById(_getSelectedVariantId);
    }

    const _getLoginUserId = localStorage.getItem("adminId");
    if(_getLoginUserId){
      __setLoginUserId(_getLoginUserId);
    }

  }, [__selectedVariantId, __selectedProductId]);

  const steps = ["Products", "Variants", "Courses"];

  return (
    <div style={{ marginTop: "0", position:"relative" }}> 

      <HeadingWithStep title="Manage Product" step={2} />

      {__selectedProductName !==null && __selectedVariantName !==null && (
      <Fragment>
        <Typography
            style={{
              fontSize: basic.headingFontSize,
              marginBottom: 0,
              marginTop: 10,
              fontWeight: 500,
              textAlign: "left",
              fontFamily:basic.fontFamily,
            }}
          >
            <strong style={{color: "#90bb41"}}>{__selectedProductName}</strong> <ArrowIcon style={{ fontSize:basic.headingFontSize, marginTop:"-2px"}} /> <strong style={{color: "#90bb41"}}>{__selectedVariantName}</strong>
          </Typography>
          
      </Fragment>
      )}

      <div style={{ display: "flex", position:"absolute", marginTop: 17, zIndex:"1000" }}>
        <BackButton backToPage="/adminDashboard/product/variant" aButtonText="Back" />
        <CustomButton
        onClick={__navigateToAddCoursePage}
        aria-label="Add Course"
        >
          <AddIcon fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-3}}/>
          <div style={{ marginLeft: 5 }}>Add Course</div>
        </CustomButton>
        
      </div>
      
      <Box
      sx={{
        height: "auto",
        width: "100%",
        ul: {
          bgcolor: "#f6f6f6",
          color: "#ffffff",
        },
      }}
    >
      <DataGridStyle
        rows={__courseData}
        columns={[
          {
            field: "productName",
            headerName: "Product Name",
            hide: true, 
          },
          {
            field: "variantName",
            headerName: "Variant Name",
            hide: true, 
          },
          //Above two fields will hide from the grid but it will still be in the export
          {
            field: "courseName",
            headerName: "Course Name",
            minWidth: 150,
            flex: 1,
            headerAlign: "left",
          },
          {
            field: "courseDescription",
            headerName: "Course Description",
            minWidth: 550,
            flex: 3,
            headerAlign: "left",
          },
          {
            field: "isVisible",
            headerName: "Visible",
            maxWidth: 110,
            flex: 1,
            headerAlign: "left",
            renderCell: (params) => {
              const isActive = params.row.isVisible;
              return <FormControlLabel
                style={{ marginLeft: 0, fontSize:basic.contentFontSize }}
                sx={{ fontSize:basic.contentFontSize,

                  "& .MuiFormControlLabel-label": {
                    fontSize:basic.contentFontSize,
                    fontFamily:basic.fontFamily,
                  }
                }}
                control={
                  <SwitchStyle
                    size="small"
                    checked={isActive === "enable" ? true : false}
                    onChange={(e) => {
                      __statusUpdateForCourse(params.row.id,params.row.courseName,e.target.checked)
                    }}
                  />
                }
                //label={isActive === "enable" ? "Enabled" : "Disabled"}
                />
            },
          },
          {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            maxWidth: 100,
            headerAlign: "left",
            disableExport: true,
            sortable: false,
            renderCell: (params) => (
              <Box>
                <IconButton
                  aria-label="Edit"                  
                  onClick={() => __handleEditCourse(params.row.id)}
                >
                  <EditIcon titleAccess='Edit Course' style={{fontSize:basic.iconsFontSize}} />
                </IconButton>
                <IconButton
                  disabled
                  aria-label="Delete"
                  onClick={() => {
                    __handleDeleteCourse(params.row.id,params.row.courseName)}
                  }
                >
                  <DeleteIcon titleAccess='Delete Course' style={{fontSize:basic.iconsFontSize}} />
                </IconButton>
              </Box>
            ),
          },
        ]}
        disableSelectionOnClick
        autoHeight={true}
        checkboxSelection={false}
        selectionModel={__selectedCourse}
        onSelectionModelChange={(aSelectedItems) => {
          __setSelectedCourseFunc(aSelectedItems)
        }}
        getRowClassName={(params) =>
          __selectedCourse.includes(params.row._id) ? "highlight-row" : ""
        }
        downloadXLFileName="course_data"
      />
      </Box>

      <ModelPopup
        open={__modalOpen}
        onClose={__handleClose}
        onConfirm={__courseDeleteConfirm}
        title="Alert"
        description={__descriptionMessage}
        loading={__loading}
        successMsg={__successMsg}
        successStatus={__messageStatus}
        confirmText="Confirm"
        cancelText="Cancel"
        onFinalButtonText="Go to Courses"
      />

      <ModelPopup
        open={__statusChangeModalOpen}
        onClose={__handleStatusChangeClose}
        onConfirm={__updateCourseStatus}
        title="Alert"
        description={__statusChangeMessage}
        loading={__loading}
        confirmText="Confirm"
        cancelText="Cancel"
      />

      <LoadingOverlay loading={__loading} />
      <MessageSnackbar
        message={__snackbarMessage}
        variant={__snackbarVariant}
        open={__snackbarOpen}
        onClose={__handleSnackbarClose}
      />

    </div>
  );
};

export default Course;
