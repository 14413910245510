import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import { basic } from '../../../themes/basic';

export default function SchoolProfile() {
  const [__customerDetails, __setCustomerDetails] = useState(null); // Set initial state to null

  useEffect(() => {
    const __getCustomerDetails = async () => {
      try {
        const __customerId = localStorage.getItem("_accountId");

        const response = await axios.get(`/customers/${__customerId}`);
        if (response.data.status === "success") {
          const data = response.data.data;
          console.log("Profile",data);
          const _defaultFields = {
            type: data.type || "N/A",
            customerName: data.customerName || data.schoolDetails?.[0]?.schoolName || "N/A",
            cpEmail: data.cpEmail || data.userEmail || "N/A",
            cpName: data.cpName || data.userName || "N/A",
            cpMobile: data.cpMobile || data.schoolDetails?.[0]?.schoolPhoneNum || "N/A",
            udiseCode: data.udiseCode || data.schoolDetails?.[0]?.udiseCode || "N/A",
            lmCode: data.lmCode || data.schoolCode || "N/A",
            address: data.address || data.schoolDetails?.[0]?.schoolAddress || "N/A",
            place: data.place || "N/A",
            district: data.district || "N/A",
            state: data.state || "N/A",
            pincode: data.pincode || "N/A",
            status: __statusUpdate(data.status) || "N/A",
          };
          __setCustomerDetails(_defaultFields);
        }
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    __getCustomerDetails();
  }, []); 

  const __statusUpdate = (aStatus) => {
    console.log("__statusUpdate",aStatus);
    if (aStatus === true) {
      return "Active";
    } 
    if (aStatus === false) {
      return "In Active";
    }
    return "N/A";
  };

  if (!__customerDetails) {
    return <Typography>Loading...</Typography>; // Show a loading state until data is fetched
  }

  return (
    <Row>
      <Col xs={7} className="m-auto">
        <h4 className="text-center">
          <Typography
            style={{
              fontSize: basic.headingFontSize,
              fontFamily: basic.fontFamily,
              marginTop: "0px",
              textAlign: "center",
              marginLeft: "0px",
              marginBottom: "30px",
              width: "100%",
              display: "block",
            }}
          >
            <strong>Customer Details</strong>
          </Typography>
        </h4>
        <Box style={{ fontFamily: basic.fontFamily, fontSize: basic.contentFontSize }}>
        {[
        { label: "Customer Name", value: __customerDetails.customerName },
        { label: "LM Code", value: __customerDetails.lmCode },
        { label: "UDISE Code", value: __customerDetails.udiseCode },
        { label: "Contact Person Name", value: __customerDetails.cpName },
        { label: "Contact Person Email", value: __customerDetails.cpEmail },
        { label: "Contact Person Mobile Number", value: __customerDetails.cpMobile },
        { label: "Customer Address", value: __customerDetails.address },
        { label: "Status", value: __customerDetails.status },
        ].map((item, index) => {
        return (
        <Row
        key={index}
        style={{
          //borderBottom: "1px solid lightgrey",
          marginBottom: "15px",
          paddingBottom: "15px",
        }}
        >
        <Col xs={5}>
          <Typography
            sx={{ fontFamily: basic.fontFamily, fontWeight: "bold" }}
            style={{ fontSize: basic.fontSize16, marginTop: "6px" }}
          >
            {item.label}
          </Typography>
        </Col>
        <Col xs={6}>
          <Typography
            sx={{ fontFamily: basic.fontFamily }}
            style={{ fontSize: basic.fontSize16, marginTop: "6px" }}
          >
            {item.value}
          </Typography>
        </Col>
        </Row>
        );
        })}
        </Box>
      </Col>
    </Row>
  );
}
