import React, { useState, useRef, useEffect } from 'react';
//import { Col, Row } from 'react-bootstrap';
//import PrimaryTextBox from '../../CmnCompnts/TextBox';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Box, IconButton, InputLabel, MenuItem, Select, FormControl, Grid, Container, FormHelperText } from '@mui/material';
import PageHeadingLeftAlign from '../page_heading_left_align';
import PageHeading from '../page_heading';
import CustomButton2 from "../common/button_two";
import { Save } from '@mui/icons-material';
import { ErrorOutline } from "@mui/icons-material";
import { basic, selectBoxWithLabel, textFieldAndShrinkStyle } from '../../../themes/basic';
import InputLabelText from '../common/input_label_text';
import TooltipIconWithMessage from '../common/toop_tip';
import MessageSnackbar from '../common/message_snackbar';
import LoadingOverlay from '../common/loading_overlay';
import InputTextFieldComponent from '../common/input_text_field';
import DropdownComponent from "../common/dropdown";
import messages from "../../../themes/messages.json";
import CancelButton from '../common/button_cancel';
import locationData from "../../../themes/location.json";

export default function EditCustomer() {

  const { customerId } = useParams(); 
  const [__isSubmitDisabled, __setIsSubmitDisabled] = useState(false);
  const [__loading, __setLoading] = useState(false);
  const [__errorsMsg, __setErrorsMsg] = useState({});
  const __defaultCustomerDetails = {
    type:"School",
    name: "",
    email: "", 
    contactname: "",
    contactnumber:"",
    udisecode: "",
    lmcode: "",
    address: "",
    place: "",
    district: "",
    state:"",
    pincode: "",
    userId: ""
  };
  const [__customerDefaultDetails, __setCustomerDefaultDetails] = useState(__defaultCustomerDetails);
  const [__customerDetails, __setCustomerDetails] = useState(__defaultCustomerDetails);

  const __customerTypeOptions = [
    { name: "School" },
    { name: "College" },
    { name: "CSR" },
    { name: "Institute" },
    { name: "Others" }    
  ];

  const __typeRef = useRef(null);
  const __nameRef = useRef(null);
  const __emailRef = useRef(null);
  const __contactnameRef = useRef(null);
  const __contactnumberRef = useRef(null);
  const __udisecodeRef = useRef(null);
  const __lmcodeRef = useRef(null);
  const __addressRef = useRef(null);
  const __placeRef = useRef(null);
  const __districtRef = useRef(null);
  const __stateRef = useRef(null);
  const __pincodeRef = useRef(null);

  const [__snackbarOpen, __setSnackbarOpen] = useState(false);
  const [__snackbarMessage, __setSnackbarMessage] = useState("");
  const [__snackbarVariant, __setSnackbarVariant] = useState("success");

  const [__states, __setStates] = useState([]);
  const [__districts, __setDistricts] = useState([]);
  const [__places, __setPlaces] = useState([]);
  const [__selectedState, __setSelectedState] = useState("");
  const [__selectedDistrict, __setSelectedDistrict] = useState("");
  const [__selectedCities, __setSelectedCities] = useState("");
  
  const __handleCustomerType = (aValue) => {
    __inputValueChange("type",aValue);
    __setErrorsMsg({});
  }

  const __inputValueChange = (aField, aValue) => {
    if (aField === "state") {
      __setSelectedState(aValue);
      const stateData = locationData.locations.find((location) => location.state === aValue);
      __setDistricts(stateData ? stateData.districts : []);
      __setPlaces([]);
      __setSelectedCities("");
      __setCustomerDetails(aPrev => ({ ...aPrev, ["district"]: "" }));
      __setCustomerDetails(aPrev => ({ ...aPrev, ["place"]: "" }));
    } else if (aField === "district") {
      //__setSelectedDistrict(aValue);
      __setCustomerDetails(aPrev => ({ ...aPrev, ["district"]: aValue }));
      const __places = __districts.find((location) => location.name === aValue);
      __setPlaces(__places.cities);
    } else if (aField === "place"){
      __setSelectedCities(aValue)
    }

    __setCustomerDetails(aPrev => ({
      ...aPrev,
      [aField]: aValue
    }));

    __setErrorsMsg((prev) => ({
      ...prev,
      [aField]: null,
    }));
  }

  const __triggerSnackbar = (message, variant) => {
    __setSnackbarMessage(message);
    __setSnackbarVariant(variant);
    __setSnackbarOpen(true);
  };

  const __validateUdiseCode = (code) => {
    const udiseCodePattern = /^\d{11,12}$/; 
    return udiseCodePattern.test(code);
  };

  const __handleSnackbarClose = (aEvent, aReason) => {
    if (aReason === "clickaway") {
      return;
    }
    __setSnackbarOpen(false);
  };

  const __scrollToField = (aRef) => {
    if (aRef && aRef.current) {
      aRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const __onUpdateCustomer = async() => { 
      let __hasError = false;

      // PIN Validation
      const _pincodeRegex = /^[1-9][0-9]{5}$/;
      if (!__customerDetails.pincode) {
        __setErrorsMsg((prev) => ({
          ...prev,
          pincode: messages.customerPage.pinCodeRequiredMsg,
        }));
        __pincodeRef.current.focus();
        __scrollToField(__pincodeRef);
        __hasError = true;
      }else if (!_pincodeRegex.test(__customerDetails.pincode)) {
        __setErrorsMsg((prev) => ({
          ...prev,
          pincode: messages.customerPage.pinCodeFormatMsg,
        }));
        __pincodeRef.current.focus();
        __scrollToField(__pincodeRef);
        __hasError = true;
      }else {
        __setErrorsMsg((prev) => ({
          ...prev,
          pincode: null,
        }));
      }

    // place Validation
    /*
    const _placeNameRegex = /^[A-Za-z ]+$/;
    if (!__customerDetails.place) {
      __setErrorsMsg((prev) => ({
        ...prev,
        place: messages.customerPage.placeRequiredMsg,
      }));
      __hasError = true;
    }else {
      __setErrorsMsg((prev) => ({
        ...prev,
        place: null,
      }));
    } */

      // state Validation
      const _stateNameRegex = /^[A-Za-z ]+$/;
      if (!__customerDetails.state) {
        __setErrorsMsg((prev) => ({
          ...prev,
          state: messages.customerPage.stateRequiredMsg,
        }));
        __hasError = true;
      }else {
        __setErrorsMsg((prev) => ({
          ...prev,
          state: null,
        }));
      }

      // district Validation
      const _districtNameRegex = /^[A-Za-z ]+$/;
      if (!__customerDetails.district) {
        __setErrorsMsg((prev) => ({
          ...prev,
          district: messages.customerPage.districtMsg,
        }));
        __hasError = true;
      }
      else {
        __setErrorsMsg((prev) => ({
          ...prev,
          district: null,
        }));
      }

    // Address one Validation
    if (!__customerDetails.address) {
      __setErrorsMsg((prev) => ({
        ...prev,
        address: messages.customerPage.addressRequiredMsg,
      }));
      __addressRef.current.focus();
      __scrollToField(__addressRef);
      __hasError = true;
    }else {
      __setErrorsMsg((prev) => ({
        ...prev,
        address: null,
      }));
    }

    

    // Contact Person Mobile Number Validation
    const _contactNumberRegex = /^\d{10}$/;
    if (!__customerDetails.contactnumber) {
      __setErrorsMsg((prev) => ({
        ...prev,
        contactnumber: messages.customerPage.contactnumberRequiredMsg,
      }));
      __contactnumberRef.current.focus();
      __scrollToField(__contactnumberRef);
      __hasError = true;
    }else if (!_contactNumberRegex.test(__customerDetails.contactnumber)) {
      __setErrorsMsg((prev) => ({
        ...prev,
        contactnumber: messages.customerPage.contactnumberFormatMsg,
      }));
      __contactnumberRef.current.focus();
      __scrollToField(__contactnumberRef);
      __hasError = true;
    }else {
      __setErrorsMsg((prev) => ({
        ...prev,
        contactnumber: null,
      }));
    }

    // Contact Person Email Validation
    const _emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!__customerDetails.email) {
      __setErrorsMsg((prev) => ({
        ...prev,
        email: messages.customerPage.emailRequiredMsg,
      }));
      __emailRef.current.focus();
      __scrollToField(__emailRef);
      __hasError = true;
    }else if (!_emailRegex.test(__customerDetails.email)) {
      __setErrorsMsg((prev) => ({
        ...prev,
        email: messages.customerPage.emailFormatMsg,
      }));
      __emailRef.current.focus();
      __scrollToField(__emailRef);
      __hasError = true;      
    }else {
        __setErrorsMsg((prev) => ({
          ...prev,
          email: null,
        }));
    }

     // Contact Person Name Validation
     const _contactPersonNameRegex = /^[A-Za-z .,'\-]+$/;
     if (!__customerDetails.contactname) {
       __setErrorsMsg((prev) => ({
         ...prev,
         contactname: messages.customerPage.contactPersonRequiredMsg,
       }));
       __contactnameRef.current.focus();
       __scrollToField(__contactnameRef);
       __hasError = true;
     }else if (!_contactPersonNameRegex.test(__customerDetails.contactname)) {
       __setErrorsMsg((prev) => ({
         ...prev,
         contactname: messages.customerPage.contactPersonFormatMsg,
       }));
       __contactnameRef.current.focus();
       __scrollToField(__contactnameRef);
       __hasError = true;
     }else {
       __setErrorsMsg((prev) => ({
         ...prev,
         contactname: null,
       }));
     }

    // UDISE Code Validation
    if(__customerDetails.type == "School" || __customerDetails.type == "College"){
      if (!__customerDetails.udisecode) {
        __setErrorsMsg((prev) => ({
          ...prev,
          udisecode: messages.customerPage.udisecodeRequiredMsg,
        }));
        __udisecodeRef.current.focus();
        __scrollToField(__udisecodeRef);
        __hasError = true;
      }else if (!__validateUdiseCode(__customerDetails.udisecode)) {
        __setErrorsMsg((prev) => ({
        ...prev,
        udisecode: messages.customerPage.udisecodeFormatMsg,
        }));
        __udisecodeRef.current.focus();
        __scrollToField(__udisecodeRef);
        __hasError = true;        
      } else {
        __setErrorsMsg((prev) => ({
          ...prev,
          udisecode: null,
        }));
      }
    }

      // LM Code Validation
      const _lmcodeRegex = /^[a-zA-Z0-9]+$/;
      if (!__customerDetails.lmcode) {
        __setErrorsMsg((prev) => ({
          ...prev,
          lmcode: messages.customerPage.lmcodeRequiredMsg,
        }));
        __lmcodeRef.current.focus();
        __scrollToField(__lmcodeRef);
        __hasError = true;
      }else if (!_lmcodeRegex.test(__customerDetails.lmcode)) {
        __setErrorsMsg((prev) => ({
        ...prev,
        lmcode: messages.customerPage.lmcodeFormatMsg,
        }));
        __lmcodeRef.current.focus();
        __scrollToField(__lmcodeRef);
        __hasError = true;
      }else {
        __setErrorsMsg((prev) => ({
          ...prev,
          lmcode: null,
        }));
      }

    // Name Validation
    const _nameRegex = /^[A-Za-z0-9 .,'\-:&()]+$/ // Allows letters, numbers, spaces, hyphens, and apostrophes
    if (!__customerDetails.name) {
      __setErrorsMsg((prev) => ({
        ...prev,
        name: messages.customerPage.nameRequiredMsg,
      }));
      __nameRef.current.focus();
      __scrollToField(__nameRef);
      __hasError = true;
    }else if (!_nameRegex.test(__customerDetails.name)) {
      __setErrorsMsg((prev) => ({
      ...prev,
      name: messages.customerPage.nameFormatMsg,
      }));
      __nameRef.current.focus(); 
      __scrollToField(__nameRef);
      __hasError = true;
    } else {
      __setErrorsMsg((prev) => ({
        ...prev,
        name: null,
      }));
    }

    //Customer Type Validation
    if (!__customerDetails.type) {
      __setErrorsMsg((prev) => ({
        ...prev,
        type: messages.customerPage.typeRequiredMsg,
      }));
      //__typeRef.current.focus();
      __hasError = true;
    }else{
      __setErrorsMsg((prev) => ({
        ...prev,
        type: null,
      }));
    }

    if (__hasError) {
      return;
    }

    try{

    
      __setLoading(true);
      const _addCustomerData = await axios.post("/customers/edit", __customerDetails);
      if(_addCustomerData.data.status === "existsEmail"){
        __emailRef.current.focus();
        __setLoading(false);
        __setErrorsMsg((prev) => ({
          ...prev,
          email: messages.errors.emailExists,
        }));
      }else if(_addCustomerData.data.status === "existslmcode"){
        __setLoading(false);
        __lmcodeRef.current.focus();
        __setErrorsMsg((prev) => ({
          ...prev,
          lmcode: messages.errors.lmCodeExists,
        }));
      }else if(_addCustomerData.data.status === "success"){
        __setErrorsMsg({});
        __setLoading(false);
        __triggerSnackbar(messages.success.editCustomer,"success"); 
      }else{
        __setLoading(false);
        __triggerSnackbar(messages.success.editCustomerFailedMsg,"error");  
      }
    }catch(error) {
      __setLoading(false);
      __triggerSnackbar(error,"error");  
    }

   


  }

  //enable or disable the submit button when field value change
  useEffect(()=> {
    if (__customerDefaultDetails && __customerDetails) {
      const _isFormModified = Object.keys(__customerDetails).some(
        (key) => __customerDetails[key] !== __customerDefaultDetails[key]
      );
      __setIsSubmitDisabled(!_isFormModified);
    }
  },[__customerDetails, __customerDefaultDetails]);


  useEffect(() => {
    console.log(customerId);
    const __getCustomerDetails = async () => {
      try {
        const _getLoginUserId = localStorage.getItem("adminId");
        const _getCustomerData = await axios.get(`/customers/${customerId}`);

        console.log("_getCustomerData",_getCustomerData);
        if(_getCustomerData.data.status === "success"){
          const _defaultFields = {
            type:_getCustomerData.data.data.type,
            name: _getCustomerData.data.data.customerName || _getCustomerData.data.data.schoolDetails[0].schoolName,
            email: _getCustomerData.data.data.cpEmail || _getCustomerData.data.data.userEmail, 
            contactname: _getCustomerData.data.data.cpName || _getCustomerData.data.data.userName,
            contactnumber: _getCustomerData.data.data.cpMobile || _getCustomerData.data.data.schoolDetails[0].schoolPhoneNum,
            udisecode: _getCustomerData.data.data.udiseCode || _getCustomerData.data.data.schoolDetails[0].udiseCode,
            lmcode: _getCustomerData.data.data.lmCode || _getCustomerData.data.data.schoolCode,
            address: _getCustomerData.data.data.address || _getCustomerData.data.data.schoolDetails[0].schoolAddress,
            place: _getCustomerData.data.data.place,
            district: _getCustomerData.data.data.district,
            state:_getCustomerData.data.data.state,
            pincode: _getCustomerData.data.data.pincode,
            customerId:customerId,
            userId:_getLoginUserId,
          };

          console.log(_defaultFields);
          
          __setCustomerDetails(_defaultFields);
          __setCustomerDefaultDetails(_defaultFields);

          const stateData = locationData.locations.find((location) => location.state === _defaultFields.state);
          __setDistricts(stateData ? stateData.districts : []);

          console.log(stateData?.districts);

          const __places = stateData.districts.find((location) => location.name === _defaultFields.district);

          console.log(__places.cities);
          __setPlaces(__places ? __places.cities : [] );

          console.log("useEffect __getCustomerDetails: ",_defaultFields);
      //}
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    }; 
    __getCustomerDetails();

    const _stateOptions = locationData.locations.map((location) => ({
      name: location.state,
    }));
    
    __setStates(_stateOptions);
    console.log(__states);

  }, [customerId]);
  

  const __customerNameLabel = __customerDetails.type ? __customerDetails.type + ": Name" : "Name";
  return (
    <div style={{ marginTop: "0px", position:"relative" }}>

      <LoadingOverlay loading={__loading} />

      <MessageSnackbar
        message={__snackbarMessage}
        variant={__snackbarVariant}
        open={__snackbarOpen}
        onClose={__handleSnackbarClose}
      />

      <Container fluid={true} style={{ padding:"0px 0px 0px 0px"}}>
        <Grid container spacing={0}>
          <Grid container spacing={0}>
            <PageHeading title="Customer Details" alignment="center" />
          </Grid>
          <Box
            sx={{
              height: "auto",
              width: "50%",
              margin:"auto",
              marginTop:"0px",
              ul: {
                bgcolor: "#f6f6f6",
                color: "#ffffff",
              },
            }}
          >
            <form>
              <Grid container spacing={2} alignItems="center">
                {/* Customer Type */}  
                <Grid item xs={12}> 
                  <DropdownComponent
                    label="Customer Type"
                    value={__customerDetails.type}
                    onChange={(e) => __handleCustomerType(e.target.value)}
                    options={__customerTypeOptions}
                    error={Boolean(__errorsMsg.type)}
                    helperText={__errorsMsg.type || ""}
                    required
                    sx={{ marginBottom: 2, mb:"1px", }}
                  />
                </Grid>           

                {/* Name */}
                <Grid item xs={12}>   
                    <InputTextFieldComponent 
                        required 
                        label={__customerNameLabel}  
                        value={__customerDetails.name}
                        onChange={(e) => __inputValueChange("name",e.target.value)}
                        error={Boolean(__errorsMsg.name)}
                        helperText={__errorsMsg.name || ""}
                        inputRef={__nameRef}
                    />
                </Grid>

                {/* LMCode */}
                <Grid item xs={12}>    
                    <InputTextFieldComponent 
                      required    
                      label="LM Code"                                  
                      value={__customerDetails.lmcode}
                      onChange={(e) => __inputValueChange("lmcode",e.target.value)}
                      error={Boolean(__errorsMsg.lmcode)}
                      helperText={__errorsMsg.lmcode || ""}
                      inputRef={__lmcodeRef}  
                    />
                </Grid>

                {/* UDISE Code */}
                <Grid item xs={12}>               
                    <InputTextFieldComponent 
                      label="UDISE Code"
                      value={__customerDetails.udisecode}
                      required={["School", "College"].includes(__customerDetails.type)}
                      onChange={(e) => __inputValueChange("udisecode",e.target.value)}
                      error={Boolean(__errorsMsg.udisecode)}
                      helperText={__errorsMsg.udisecode || ""}
                      inputRef={__udisecodeRef}
                    />
                </Grid>  

                {/* Contact Person Name */}
                <Grid item xs={12}>                                                         
                    <InputTextFieldComponent 
                      required 
                      label="Contact Person Name"
                      value={__customerDetails.contactname}
                      onChange={(e) => __inputValueChange("contactname",e.target.value)}
                      error={Boolean(__errorsMsg.contactname)}
                      helperText={__errorsMsg.contactname || ""}
                      inputRef={__contactnameRef}
                    />
                </Grid>

                {/* Contact Person Email */} 
                <Grid item xs={12}>                                     
                    <InputTextFieldComponent 
                      label="Contact Person Email"
                      required       
                      value={__customerDetails.email}
                      onChange={(e) => __inputValueChange("email",e.target.value)}
                      error={Boolean(__errorsMsg.email)}
                      helperText={__errorsMsg.email || ""}
                      inputRef={__emailRef}
                    />
                </Grid>

                {/* Contact Person Number */}
                <Grid item xs={12}>                   
                    <InputTextFieldComponent 
                      required 
                      label="Contact Person Mobile Number"  
                      value={__customerDetails.contactnumber}
                      onChange={(e) => __inputValueChange("contactnumber",e.target.value)}
                      error={Boolean(__errorsMsg.contactnumber)}
                      helperText={__errorsMsg.contactnumber || ""}
                      inputRef={__contactnumberRef}
                    />     
                </Grid>

                {/* Address 1 */}
                <Grid item xs={12}>   
                  <InputTextFieldComponent 
                    required 
                    //size="large" 
                    label="Address"
                    //multiline
                    rows={1}
                    value={__customerDetails.address}
                    onChange={(e) => __inputValueChange("address",e.target.value)}
                    error={Boolean(__errorsMsg.address)}
                    helperText={__errorsMsg.address || ""}
                    inputRef={__addressRef}
                  />
                </Grid>

                {/* State */}
                <Grid item xs={12}>  
                  {/*<InputTextFieldComponent 
                    required 
                    label="State"
                    value={__customerDetails.state}
                    onChange={(e) => __inputValueChange("state",e.target.value)}
                    error={Boolean(__errorsMsg.state)}
                    helperText={__errorsMsg.state || ""}    
                    inputRef={__stateRef}
                  /> */}
                  <DropdownComponent
                    label="State"
                    value={__customerDetails.state}
                    onChange={(e) => __inputValueChange("state",e.target.value)}
                    options={__states}
                    error={Boolean(__errorsMsg.state)}
                    helperText={__errorsMsg.state || ""}
                    required
                    sx={{ marginBottom: 2, mb:"1px", }}
                  />
                </Grid>

                 {/* District */}
                 <Grid item xs={12}> 

                  <DropdownComponent
                      label="District"
                      value={__customerDetails.district}
                      onChange={(e) => __inputValueChange("district",e.target.value)}
                      options={__districts}
                      error={Boolean(__errorsMsg.district)}
                      helperText={__errorsMsg.district || ""}
                      required
                      sx={{ marginBottom: 2, mb:"1px", }}
                    />

                 {/*} <InputTextFieldComponent 
                      required 
                      label="District"
                      value={__customerDetails.district}
                      onChange={(e) => __inputValueChange("district",e.target.value)}
                      options={__states}
                      error={Boolean(__errorsMsg.district)}
                      helperText={__errorsMsg.district || ""}   
                      inputRef={__districtRef}  
                  /> */}
                </Grid>

                {/* place */}
                <Grid item xs={12}>  

                  <DropdownComponent
                    label="Place"
                    value={__customerDetails.place}
                    onChange={(e) => __inputValueChange("place",e.target.value)}
                    options={__places}
                    error={Boolean(__errorsMsg.place)}
                    helperText={__errorsMsg.place || ""}
                    sx={{ marginBottom: 2, mb:"1px", }}
                  /> 

                  {/*<InputTextFieldComponent 
                    required 
                    label="Place/Town"
                    value={__customerDetails.place}
                    onChange={(e) => __inputValueChange("place",e.target.value)}
                    error={Boolean(__errorsMsg.place)}
                    helperText={__errorsMsg.place || ""}     
                    inputRef={__placeRef}
                    /> */ }
                </Grid>

                {/* Pin */}
                <Grid item xs={12}>                  
                  <InputTextFieldComponent 
                      required 
                      label="PIN Code"                       
                      value={__customerDetails.pincode}
                      onChange={(e) => __inputValueChange("pincode",e.target.value)}
                      error={Boolean(__errorsMsg.pincode)}
                      helperText={__errorsMsg.pincode || ""} 
                      inputRef={__pincodeRef}
                    />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12} sx={{ mb:2}} style={{ textAlign: "center" }}>
                <CancelButton backToPage="/adminDashboard/customer" aButtonText="Cancel" />
                  <CustomButton2        
                    disabled={__isSubmitDisabled}           
                    aria-label="Submit"
                    onClick={__onUpdateCustomer}
                    //style={{margin:"0px 0px 0px 0px"}}
                    >
                      <Save fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }} />
                      <div style={{ marginLeft: 5 }}>Submit</div>
                  </CustomButton2>
                </Grid>
                
              </Grid>
            </form>
          </Box>
        </Grid>
      </Container>

    </div>
  )
}
