import React, { useState, useEffect } from 'react';
import {  Box, IconButton, Grid, TextField, CircularProgress, List, ListItem } from '@mui/material';
import { Save } from '@mui/icons-material';
import axios from 'axios';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageHeading from '../page_heading';
import CustomButton2 from "../common/button_two";
import ModelPopup from "../common/modal_popup";
import { basic, textFieldAndShrinkStyle } from '../../../themes/basic';
import LoadingOverlay from '../common/loading_overlay';
import MessageSnackbar from '../common/message_snackbar';
import CancelButton from '../common/button_cancel';

const AddCourse = () => {
  const [__courseName, __setCourseName] = useState(null);
  const [__courseDescription, __setCourseDescription] = useState(null);
  const [__modalOpen, __setModalOpen] = useState(false);
  const [__loading, __setLoading] = useState(false);
  const [__successMsg, __setSuccessMsg] = useState(null);
  const [__messageStatus, __setMessageStatus] = useState(null);
  const [__courseExists, __setCourseExists] = useState(false); 
  const [__checkMessage, __setCheckMessage] = useState(''); 
  const [__suggestions, __setSuggestions] = useState([]); 
  const [__fetchingSuggestions, __setFetchingSuggestions] = useState(false);
  const [__selecteProductId, __setSelecteProductId] = useState([]);
  const [__productCourseData, __setProductCourseData] = useState([]);
  const [__selectedVariantId, __setSelectedVariantId] = useState(null);
  const [__clickFetchSuggestionList, __setClickFetchSuggestionList] = useState(false);
  const [__isCourseNameValid, __setIsCourseNameValid] = useState(false);
  const [__errorMessage, __setErrorMessage] = useState('');

  const [__snackbarOpen, __setSnackbarOpen] = useState(false);
  const [__snackbarMessage, __setSnackbarMessage] = useState("");
  const [__snackbarVariant, __setSnackbarVariant] = useState("success"); 

  const [__loginUserId, __setLoginUserId] = useState("");


  const __navigate = useNavigate() 

  const __handleOpen = () => __setModalOpen(true);
  const __handleClose = () => __setModalOpen(false);

  const __checkCourseNameExists = async (aCourseValue) => {
    const _getProductId = sessionStorage.getItem("_selectedProductID");
    try {
    const _getSelectedCourse = await axios.get(`/product/${_getProductId}`);

    const _getProductAssocationData = _getSelectedCourse.data.data.productAssociation;
    let _variantIndex = _getProductAssocationData.findIndex(
      assoc => assoc.variantId._id.toString() === __selectedVariantId
    );
    const _getCurrentVarintCourses = _getProductAssocationData[_variantIndex]?.courses;
    const _productCourseData = _getCurrentVarintCourses?.map((aItem) => ({
      courseName:aItem.courseId.courseName,
      courseDescription:aItem.courseId.courseDescription,
      isVisible:aItem.isVisible ? 'Inactive' : 'Active',
      id: aItem._id,
    }));

    const _foundCourseName = _productCourseData.find(course => course.courseName === aCourseValue);
    if(_foundCourseName){
      __setIsCourseNameValid(false);
      __setCourseExists(true);
      __setCheckMessage("This course name is already in use for this product.");
      __setSuggestions([]);  
    }else{
      if (!__clickFetchSuggestionList) {
        __fetchCourseSuggestions(aCourseValue);
      }
      __setIsCourseNameValid(true);
      __setCourseExists(false);
      __setCheckMessage("This course name is available for this product.");
    }


    console.log(_productCourseData);
    console.log(aCourseValue);

    //__setProductCourseData(_productCourseData);
      /*const _courseResponse = await axios.post("/course/check_course_name", { productId:__selecteProductId,courseName: aName });
      if (_courseResponse.data.exists){
        __setCourseExists(true);
        __setCheckMessage("This course name is already in use.");
        __setSuggestions([]);
      }else{
        __fetchCourseSuggestions(aName);
        __setCourseExists(false);
        __setCheckMessage("Course name is available.");
      } */
    } catch (error) {
      console.error("Error checking course name:", error);
    }
  };

  const __fetchCourseSuggestions = async (aInput) => {
    try {
      __setFetchingSuggestions(true);
      const _courseResponse = await axios.post("/course/search", { query: aInput });
      console.log("__fetchCourseSuggestions",_courseResponse);
      __setSuggestions(_courseResponse.data.data); 
      __setFetchingSuggestions(false);
    } catch (error) {
      console.error("Error fetching course suggestions:", error);
      __setFetchingSuggestions(false);
    }
  };

  const __courseNameValueUpdate = (aEventObject) => {
    const _courseNameValue = aEventObject.target.value;
    const _regex = /^[a-zA-Z0-9 ]*$/;
    __setCourseName(_courseNameValue);
   
    if (_courseNameValue.trim().toLowerCase() === "all") {
      __setSuggestions([]); 
      __setIsCourseNameValid(false);      
      __setCourseExists(false);
       __setCheckMessage('');      
      __setErrorMessage('Course name "All" or "all" is not allowed.');
      return; 
    }

    if (!_regex.test(_courseNameValue)) {
      __setSuggestions([]); 
      __setIsCourseNameValid(false);      
      __setCourseExists(false);
      __setCheckMessage('');      
      __setErrorMessage('Special characters are not allowed.');
      return; 
    }

    __setErrorMessage('');      

    if (_courseNameValue === "" || _courseNameValue.trim().length < 0) {
      __setIsCourseNameValid(false);
      __setSuggestions([]); 
    }else{
      __setIsCourseNameValid(false);
      __setClickFetchSuggestionList(false);
      __checkCourseNameExists(_courseNameValue); 
    }
  };

  const __handleSelectCourse = (courseName) => {
    __setCourseName(courseName); // Set selected course name
    __setClickFetchSuggestionList(true);
    __setSuggestions([]); // Clear suggestions after selection
    __checkCourseNameExists(courseName);
  };

  const __handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    __setSnackbarOpen(false);
  };

  const __triggerSnackbar = (message, variant) => {
    __setSnackbarMessage(message);
    __setSnackbarVariant(variant);
    __setSnackbarOpen(true);
  };

  const __addCourseFunc = async (e) => {
    e.preventDefault();
    __setModalOpen(false);
    __setLoading(true);
    const _getSelectedProductId = sessionStorage.getItem("_selectedProductID");

    const _courseData = {
      courseName:__courseName,
      courseDescription:__courseDescription,
      productId: _getSelectedProductId,
      variantId:__selectedVariantId,
      userId: __loginUserId
    };

    console.log("__addCourseFunc",_courseData);
    
    const _addCourseData = await axios.post("/course/add", _courseData);
    if(_addCourseData.data.status=="success"){
      __setLoading(false);
      __setCourseName("");
      __setCourseDescription("");     
     // __setMessageStatus("success");
      //__setSuccessMsg("The course has been successfully added.");
      __triggerSnackbar("The course has been successfully added.","success"); 
    }else{
      __setLoading(false);
      __triggerSnackbar("Failed to add course.","error"); 
      //__setMessageStatus("failed");
      //__setSuccessMsg("Failed to add course.");
    }
  };

  const __handleDescription = (aCourseDescription) => {
    __setSuggestions([]);  
    __setCourseDescription(aCourseDescription);
  }

  useEffect(() => {
    const _getSelectedProductId = sessionStorage.getItem("_selectedProductID");
    if(_getSelectedProductId){
      __setSelecteProductId(_getSelectedProductId);
    }

    const _getSelectedVariantId = sessionStorage.getItem("_selectedVariantID");
    if(_getSelectedVariantId){
      __setSelectedVariantId(_getSelectedVariantId);
    }

    const _getLoginUserId = localStorage.getItem("adminId");
    if(_getLoginUserId){
      __setLoginUserId(_getLoginUserId);
    }
    // Fetch courses from API
  }, []);

  return (
    <div style={{ marginTop: "0",position:"relative" }}> 
    
      <PageHeading title="Add Course" style={{marginBottom:"20px"}} />
      <Box
        sx={{
          height: "auto",
          width: "60%",
          margin:"auto auto auto auto",
          marginTop:"0px",
          ul: {
            bgcolor: "#f6f6f6",
            color: "#ffffff",
          },
        }}
       >
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item sm={12}>
              <TextField
                id="course"
                label="Course Name"
                variant="outlined"
                size="small"
                required
                type="text"
                style={{ width: "100%" }}
                value={__courseName}
                onChange={__courseNameValueUpdate}
                autoComplete="off" 
                sx={textFieldAndShrinkStyle}
                error={!!__errorMessage || __courseName === ""}
                helperText={__errorMessage || (__courseName === "" ? "Course name is required." : "")}
              />

            {__errorMessage ==="" && __courseName && <div style={{ fontSize:basic.contentFontSize,color: __courseExists ? 'red' : 'green' }}>{__checkMessage}</div>}

            {/* Suggestions list */}
            {__fetchingSuggestions && !__clickFetchSuggestionList ? (
              <CircularProgress style={{ color: basic.greenColor }} size={24} />
            ) : (
              __suggestions.length > 0 && !__clickFetchSuggestionList && (
                <List style={{ display:"block", backgroundColor:"#fff"}}>
                  {__suggestions.map((courseItem) => (
                    <ListItem
                      key={courseItem._id}
                      onClick={() => __handleSelectCourse(courseItem.courseName)}
                      style={{ color: basic.colorWhite, cursor:"pointer", backgroundColor:basic.logoGreyColor, borderRadius:"5px", marginRight:"5px", display:"inline-block", width:"auto", marginBottom:"5px", paddingTop:"5px", fontSize:basic.contentFontSize}}
                      title='Select Course'
                      sx={{
                        "& li.MuiListItem-root:last-child": {
                          borderBottom: "0px",
                        },
                      }}  
                    >
                      {courseItem.courseName}
                    </ListItem>
                  ))}
                </List>
              )
            )}

              <TextField
                id="description"
                label="Course Description"
                value={__courseDescription}
                variant="outlined"
                size="small"
                required
                type="text"
                multiline
                rows={4}
                style={{ width: "100%", marginTop:"20px", marginBottom:"20px" }}
                onChange={(e) => __handleDescription(e.target.value)}
                error={__courseDescription === ""}
                sx={textFieldAndShrinkStyle}
                helperText={
                  __courseDescription === ""
                    ? "Course description is required."
                    : ""
                }
              />
              <div style={{ margin: 10 }} />
              <Grid container spacing={2} style={{ marginBottom: 20, marginLeft:"0px" }}>
              <CustomButton2
              onClick={__handleOpen}
              disabled={
                !(__isCourseNameValid && __courseDescription && __courseDescription.length > 0) ||
                __courseExists 
              }
              aria-label="Save"
              >
                <Save fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }}/>
                <div style={{ marginLeft: 5 }}>Save</div>
              </CustomButton2>
              <CancelButton backToPage="/adminDashboard/product/course" aButtonText="Cancel" />
            </Grid>
            
            </Grid>
            <Grid item sm={4}>
            </Grid>
        </Grid>
      </Box>

      <ModelPopup
        open={__modalOpen}
        onClose={__handleClose}
        onConfirm={__addCourseFunc}
        title="Alert"
        description="Are you sure you want to add this course?"
        loading={__loading}
        successMsg={__successMsg}
        successStatus={__messageStatus}
        confirmText="Confirm"
        cancelText="Cancel"
        onGoToNext={() => { __navigate("/adminDashboard/course") }}
        onFinalButtonText="Go to Courses"
      />

      <LoadingOverlay loading={__loading} />
      <MessageSnackbar
        message={__snackbarMessage}
        variant={__snackbarVariant}
        open={__snackbarOpen}
        onClose={__handleSnackbarClose}
      />

    </div>
  );
};

export default AddCourse;
