import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Box, List, Typography, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse  } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School'; 
import { basic } from '../../../themes/basic';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router-dom";
import { activeStyleForSubMenu, hoverStyleForSubMenu } from '../../../themes/basic';

const LeftSidebar = ({ menuItems, selectedBtn, activeStyle, hoverStyle, selectedSchool }) => {
    const [openMenu, setOpenMenu] = useState(null); // State to track open submenu
    const [activePath, setActivePath] = useState(null); // Track the active path


    const handleToggleSubMenu = (menuName) => {
        setOpenMenu(openMenu === menuName ? null : menuName); // Toggle submenu visibility
    };

    const handleActivePath = (path) => {
        setActivePath(path);
    };

    const _navigate = useNavigate();
    

    /*const [isBlinking, setIsBlinking] = useState(false);

    useEffect(() => {
      const interval = setInterval(() => {
        setIsBlinking((prev) => !prev);
      }, 2000); 
  
      return () => clearInterval(interval);
    }, []); */


    const renderSubMenuItems = (subMenuItems) => {
        return (
            <List disablePadding>
                {subMenuItems.map((subItem, subIndex) => (

                    <ListItem key={subIndex} disablePadding>
                        <ListItemButton
                            sx={selectedBtn === subItem.name ? activeStyleForSubMenu : hoverStyleForSubMenu}
                            style={{ paddingLeft: "30px" }}
                            onClick={() => {
                                handleActivePath(subItem.name);
                                subItem.onClick && subItem.onClick();
                            }}
                           // onClick={subItem.onClick}
                        >
                            <ListItemIcon className="custom-list-item-icon">{subItem.icon}</ListItemIcon>
                            <ListItemText className="custom-list-item-text" primary={subItem.text} />
                            {subItem.subMenuItems && (openMenu === subItem.name ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
                        </ListItemButton>
                        {subItem.subMenuItems && (
                            <Collapse in={openMenu === subItem.name} timeout="auto" unmountOnExit>
                                {renderSubMenuItems(subItem.subMenuItems)} {/* Recursively render nested submenus */}
                            </Collapse>
                        )}
                    </ListItem>
                ))}
            </List>
        );
    };

    const _renderSchoolName = () => {
        return (
          <Box 
          sx={{ justifyContent: 'space-between',padding: '0px 0px 10px 0px', borderRadius: '0px',
              marginBottom: '0px',boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)'
          }}
          >
          <Box sx={{ display: "block", textAlign: "center", width: "100%" }}>
              <SchoolIcon sx={{ color: basic.greenColor, marginRight: '8px' }} />
              <Typography style={{color:basic.colorBlack, fontSize:basic.headingFontSize}} variant="subtitle1" fontWeight="bold" color="textPrimary">
                  {selectedSchool}
              </Typography>
          </Box>
          </Box>
        );
    };

    const _exitAccount = () => {
        return (
            <Box 
                sx={{
                    padding: '0px 0px', borderRadius: '0px', position: "fixed",
                    bottom: "0px",fontFamily:basic.fontFamily,
                    fontSize: basic.buttonFontSize,
                    backgroundColor: basic.logoGreyColor,
                    width: "220px",  
                    left: "0px",  
                    display: "flex", 

                }}
                >
                    <ListItemButton
                        onClick={() => {
                            _navigate("/adminDashboard/customer");
                        }}
                        title="EXIT ACCOUNT"
                        sx={hoverStyle}
                        style={{ 
                          display: "flex", 
                          padding: "10px 20px 10px 10px", 
                          borderRadius: "0px", 
                          //backgroundColor:isBlinking ? basic.greenColor : "transparent",
                          //color: isBlinking ? basic.colorWhite : basic.colorWhite, 
                          backgroundColor:"transparent",
                          color: basic.colorWhite, 
                          transition: "background-color 0.3s ease-in-out",
                        }}                        
                    >
                            <ExitToAppIcon 
                                style={{ 
                                    fontSize:basic.buttonFontSize,
                                    color: basic.colorWhite, 
                                    marginRight: "10px", 
                                    marginTop:"-1px"
                                }} 
                            />
                            
                            <Typography 
                                style={{
                                    color: basic.colorWhite,
                                    fontSize: basic.buttonFontSize,  
                                    fontWeight: "500", 
                                    textTransform: "capitalize"
                                }}
                            >
                                EXIT ACCOUNT
                            </Typography>
                    </ListItemButton>
                </Box>
        );
    }


    return (
        <>
            {selectedSchool && _renderSchoolName()}

            <List>
                {menuItems.map((item, index) => (
                    <div key={index}>
                        <ListItem  disablePadding>
                            <ListItemButton 
                                style={{ paddingLeft:"10px", paddingRight:"10px", ...(item.disabled && { color: "gray", pointerEvents: "none", opacity: 0.5 })}}
                                sx={selectedBtn === item.name ? activeStyle : hoverStyle}
                                /*sx={
                                    openMenu === item.name
                                      ? hoverStyle // Apply hoverStyle when submenu is open
                                      : selectedBtn === item.name
                                      ? activeStyle // Apply activeStyle when the button is selected
                                      : hoverStyle // Default hover style
                                  } */
                                /*onClick={() => {
                                    item.subMenuItems ? handleToggleSubMenu(item.name) : item.onClick();
                                }}*/

                                onClick={() => {
                                    if (item.subMenuItems) {
                                        handleToggleSubMenu(item.name);
                                    } else {
                                        handleActivePath(selectedBtn);
                                        item.onClick && item.onClick();
                                    }
                                }}
                                //onClick={!item.disabled ? item.onClick : undefined}
                            >
                                <ListItemIcon style={{ fontSize:basic.iconsFontSize}}>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                                {item.subMenuItems && (openMenu === item.name ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
                            </ListItemButton>
                        </ListItem>
                        {item.subMenuItems && (
                            <Collapse in={openMenu === item.name} timeout="auto" unmountOnExit>
                                {renderSubMenuItems(item.subMenuItems)} {/* Render the submenu items */}
                            </Collapse>
                        )}
                    </div>
                ))}
            </List>
            
            {/* Exit Account */}
            {selectedSchool && _exitAccount()}
        </>
    );
};

export default LeftSidebar;
