import React, { useState, useEffect } from 'react';
import {  Box, IconButton, Grid, TextField, CircularProgress, List, ListItem } from '@mui/material';
import { Save } from '@mui/icons-material';
import axios from 'axios';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageHeading from '../page_heading';
import CustomButton2 from "../common/button_two";
import ModelPopup from "../common/modal_popup"
import { basic,textFieldAndShrinkStyle } from '../../../themes/basic';
import LoadingOverlay from '../common/loading_overlay';
import MessageSnackbar from '../common/message_snackbar';
import CancelButton from '../common/button_cancel';

const AddVariant = () => {
  const [__variantName, __setVariantName] = useState(null);
  const [__variantDescription, __setVariantDescription] = useState(null);
  const [__modalOpen, __setModalOpen] = useState(false);
  const [__loading, __setLoading] = useState(false);
  const [__successMsg, __setSuccessMsg] = useState(null);
  const [__messageStatus, __setMessageStatus] = useState(null);
  const [__variantExists, __setVariantExists] = useState(false); 
  const [__checkMessage, __setCheckMessage] = useState(''); 
  const [__suggestions, __setSuggestions] = useState([]); 
  const [__fetchingSuggestions, __setFetchingSuggestions] = useState(false);
  const [__selecteProductId, __setSelecteProductId] = useState([]);
  const [__productVariantData, __setProductVariantData] = useState([]);
  const [__clickFetchSuggestionList, __setClickFetchSuggestionList] = useState(false);
  const [__errorMessage, __setErrorMessage] = useState('');
  const [__isVariantNameValid, __setIsVariantNameValid] = useState(false);

  const [__snackbarOpen, __setSnackbarOpen] = useState(false);
  const [__snackbarMessage, __setSnackbarMessage] = useState("");
  const [__snackbarVariant, __setSnackbarVariant] = useState("success");

  const [__loginUserId, __setLoginUserId] = useState("");

  const __navigate = useNavigate() 

  const __handleOpen = () => __setModalOpen(true);
  const __handleClose = () => __setModalOpen(false);

  const __checkVariantNameExists = async (aName) => {
    const _getProductId = sessionStorage.getItem("_selectedProductID");
    try {

    const _getSelectedVariant = await axios.get(`/product/${_getProductId}`);
    const _productVariantData = _getSelectedVariant.data.data.productAssociation?.map((aItem) => ({
      variantName:aItem.variantId.variantName,
      variantDescription:aItem.variantId.variantDescription,
      isVisible:aItem.isVisible ? 'Inactive' : 'Active',
      id: aItem._id,
    }));

    const _foundVariantName = _productVariantData.find(variant => variant.variantName.toLowerCase() === aName.toLowerCase());
    if(_foundVariantName){
      __setIsVariantNameValid(false);
      __setVariantExists(true);
      __setCheckMessage("This variant name is already in use for this product.");
      __setSuggestions([]);       
    }else{
      console.log(__clickFetchSuggestionList);

      if (!__clickFetchSuggestionList) {
        __fetchVariantSuggestions(aName);
      }
      __setIsVariantNameValid(true); 
      __setVariantExists(false);
      __setCheckMessage("This variant name is available for this product.");
      
    }


    //__setProductVariantData(_productVariantData);
      /*const _variantResponse = await axios.post("/variant/check_variant_name", { productId:__selecteProductId,variantName: aName });
      if (_variantResponse.data.exists){
        __setVariantExists(true);
        __setCheckMessage("This variant name is already in use.");
        __setSuggestions([]);
      }else{
        __fetchVariantSuggestions(aName);
        __setVariantExists(false);
        __setCheckMessage("Variant name is available.");
      } */
    } catch (error) {
      console.error("Error checking variant name:", error);
    }
  };

  const __fetchVariantSuggestions = async (aInput) => {
    try {
      __setFetchingSuggestions(true);
      const _variantResponse = await axios.post("/variant/search", { query: aInput });
      console.log("__fetchVariantSuggestions",_variantResponse);
      __setSuggestions(_variantResponse.data.data); 
      __setFetchingSuggestions(false);
    } catch (error) {
      console.error("Error fetching variant suggestions:", error);
      __setFetchingSuggestions(false);
    }
  };

  const __handleVarinantNameChange = (aEventObject) => {
    const _variantValue = aEventObject.target.value;
    const _regex = /^[a-zA-Z0-9 ]*$/;

    __setVariantName(_variantValue);

    console.log(_variantValue.trim());

    if (_variantValue.trim().toLowerCase() === "all") {
      __setSuggestions([]);
      __setIsVariantNameValid(false);      
      __setVariantExists(false);
      __setCheckMessage('');      
      __setErrorMessage('Variant name "All" or "all" is not allowed.');
      return; 
    }

    if (!_regex.test(_variantValue)) {
      __setIsVariantNameValid(false);      
      __setVariantExists(false);
      __setCheckMessage('');      
      __setErrorMessage('Special characters are not allowed.');
      return; 
  }
    __setErrorMessage("");

    if (_variantValue === "" || _variantValue.trim().length < 0) {
      __setIsVariantNameValid(false);  
      __setSuggestions([]);
    }else{
      __setIsVariantNameValid(false);  
      __setClickFetchSuggestionList(false);
      __checkVariantNameExists(_variantValue); 
    }
  };

  const __handleSelectVariant = (aVariantValue) => {
    console.log(aVariantValue);
    __setClickFetchSuggestionList(true);
    __setVariantName(aVariantValue); // Set selected variant name
    __setSuggestions([]); // Clear suggestions after selection
    __checkVariantNameExists(aVariantValue);
  };

  const __handleSnackbarClose = (aEvent, aReason) => {
    if (aReason === "clickaway") {
      return;
    }
    __setSnackbarOpen(false);
  };

  const __triggerSnackbar = (aMessage, aMessageType) => {
    __setSnackbarMessage(aMessage);
    __setSnackbarVariant(aMessageType);
    __setSnackbarOpen(true);
  };

  const __addVariantFunc = async (e) => {
    e.preventDefault();
    __setModalOpen(false);
    __setLoading(true);
    const _getSelectedProductId = sessionStorage.getItem("_selectedProductID");

    const _variantData = {
      variantName:__variantName,
      variantDescription:__variantDescription,
      productId: _getSelectedProductId,
      userId: __loginUserId
    };

    console.log("__addVariantFunc",_variantData);
    
    const _addVariantData = await axios.post("/variant/add", _variantData);
    if(_addVariantData.data.status=="success"){
      __setLoading(false);
      __setVariantName("");
      __setVariantDescription("");     
      __triggerSnackbar("The variants has been successfully added.","success"); 
      // __setMessageStatus("success");
      //__setSuccessMsg("The variants has been successfully added.");
    }else{
      __setLoading(false);
      __triggerSnackbar("Failed to add variant.","error"); 
      //__setMessageStatus("failed");
     // __setSuccessMsg("Failed to add variant.");
    }
  };

  const __handleDescription = (aVariantDescription) => {
    console.log(__isVariantNameValid);
    __setSuggestions([]);  
    __setVariantDescription(aVariantDescription);
  }

  useEffect(() => {
    const _getSelectedProductId = sessionStorage.getItem("_selectedProductID");
    const _getLoginUserId = localStorage.getItem("adminId");
    if(_getSelectedProductId){
      __setSelecteProductId(_getSelectedProductId);
      __setLoginUserId(_getLoginUserId);
      __setClickFetchSuggestionList(false);
    }
    // Fetch variants from API
  }, []);

  return (
    <div style={{ marginTop: "0",position:"relative" }}> 
  
      <PageHeading title="Add Variant" style={{marginBottom:"20px"}} />
      <Box
        sx={{
          height: "auto",
          width: "60%",
          margin:"auto auto auto auto",
          marginTop:"0px",
          ul: {
            bgcolor: "#f6f6f6",
            color: "#ffffff",
          },
        }}
       >
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item sm={12}>
              <TextField
                id="variant"
                label="Variant Name"
                variant="outlined"
                size="small"
                required
                type="text"
                style={{ width: "100%" }}
                value={__variantName}
                onChange={__handleVarinantNameChange}
                autoComplete="off" 
                sx={textFieldAndShrinkStyle}
                error={!!__errorMessage || __variantName === ""}
                helperText={__errorMessage || (__variantName === "" ? "Variant name is required." : "")}
              />

            {__errorMessage ==="" && __variantName && <div style={{ fontSize:basic.contentFontSize,color: __variantExists ? 'red' : 'green' }}>{__checkMessage}</div>}

            {/* Suggestions list */}
            {__fetchingSuggestions && !__clickFetchSuggestionList ? (
              <CircularProgress style={{ color: basic.greenColor }} size={24} />
            ) : (
              __suggestions.length > 0 && !__clickFetchSuggestionList && (
                <List style={{ display:"block", backgroundColor:"#fff"}}>
                  {__suggestions.map((variantItem) => (
                    <ListItem
                      key={variantItem._id}
                      onClick={() => __handleSelectVariant(variantItem.variantName)}
                      style={{ color: basic.colorWhite, cursor:"pointer", backgroundColor:basic.logoGreyColor, borderRadius:"5px", marginRight:"5px", display:"inline-block", width:"auto", marginBottom:"5px", paddingTop:"5px", fontSize:basic.contentFontSize}}
                      title='Select Variant'
                      sx={{
                        "& li.MuiListItem-root:last-child": {
                          borderBottom: "0px",
                        },
                      }}  
                    >
                      {variantItem.variantName}
                    </ListItem>
                  ))}
                </List>
              )
            )}

              <TextField
                id="description"
                label="Variant Description"
                value={__variantDescription}
                variant="outlined"
                size="small"
                required
                type="text"
                multiline
                rows={4}
                style={{ width: "100%", marginTop:"20px", marginBottom:"20px" }}
                onChange={(e) => __handleDescription(e.target.value)}
                error={__variantDescription === ""}
                sx={textFieldAndShrinkStyle}
                helperText={
                  __variantDescription === ""
                    ? "Variant description is required."
                    : ""
                }
              />
              <div style={{ margin: 10 }} />
              <Grid container spacing={2} style={{ marginBottom: 20, marginLeft:"0px" }}>
                <CustomButton2
                  onClick={__handleOpen}
                  disabled={
                  !(__isVariantNameValid && __variantDescription && __variantDescription.length > 0) ||
                  __variantExists 
                  }
                  aria-label="Save"
                  >
                  <Save fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }}/>
                  <div style={{ marginLeft: 5 }}>Save</div>
                </CustomButton2>
                <CancelButton backToPage="/adminDashboard/product/variant" aButtonText="Cancel" />
              </Grid>
          
            
            </Grid>
            <Grid item sm={4}>
            </Grid>
        </Grid>
      </Box>

      <ModelPopup
        open={__modalOpen}
        onClose={__handleClose}
        onConfirm={__addVariantFunc}
        title="Alert"
        description="Are you sure you want to add this variant?"
        loading={__loading}
        successMsg={__successMsg}
        successStatus={__messageStatus}
        confirmText="Confirm"
        cancelText="Cancel"
        onGoToNext={() => { __navigate("/adminDashboard/variant") }}
        onFinalButtonText="Go to Variants"
      />

      <LoadingOverlay loading={__loading} />

      <MessageSnackbar
        message={__snackbarMessage}
        variant={__snackbarVariant}
        open={__snackbarOpen}
        onClose={__handleSnackbarClose}
      />
    </div>
  );
};

export default AddVariant;
